@import 'src/styles/common.module';

.textarea {
  border-color: var(--textarea-border-color, $dark-teal);
  border-width: var(--textarea-border-width, 1px);
  border-style: var(--textarea-border-style, solid);
  border-radius: 4px;
  padding: 8px 16px;
  width: 100%;
  height: var(--textarea-height, 85px); /* To display 5 lines of text */
  font-size: 14px;

  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    color: $dark-grey;
  }
}

.disableResize {
  resize: none;
}

.shadedTextarea {
  --textarea-border-style: none;
  padding: 7px 15px;
  border: none;
}

.limit{
  text-align: right;
  color: $dark-teal;

}

.hasError{
  border-color: $red;
}