@import 'src/styles/common.module';

.tabsContainer {
    background-color: transparent;
    padding: 0;
    gap: 0px;
}

.tabItem {
    text-transform: capitalize;
    height: 100%;
    fill: $dark-grey;
    display: flex;
    gap: 10px;
    margin-right: 30px;
    padding-bottom: 10px;
    border-bottom: 2px solid transparent;

    svg {
        margin: 0 auto;
        display: block;
    }

}

.tabItemSelected {
    fill: $dark-teal;
    border-color: $dark-teal;
}