@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    :root {
      --color-primary: 7, 103, 105;
      --color-primary-navy: 38, 45, 56;
      --color-cyan: 70, 220, 211;
      --color-extra-light-teal: 187, 188, 204;
      --color-light-teal: 124, 164, 166;
      --color-medium-light-teal: 62, 168, 166;
      --color-teal: 18, 155, 158;
      --color-medium-dark-teal: 18, 155, 158;
      --color-dark-teal: 7, 103, 105;
      --color-extra-dark-teal: 0, 58, 60;
      --color-extra-light-grey: 246, 246, 246;
      --color-medium-light-grey: 230, 230, 230;
      --color-light-grey: 204, 204, 204;
      --color-grey: 153, 153, 153;
      --color-dark-grey: 102, 102, 102;
      --color-light-green: 221, 255, 212;
      --color-green: 46, 173, 14;
      --color-dark-green: 90, 130, 77;
      --color-dark-blue: 7, 58, 105;
      --color-medium-blue: 207, 232, 255;
      --color-red: 173, 24, 14;
      --color-black: 33, 33, 33;
      --color-orange: 207, 133, 21;
      --color-light-orange: 255, 238, 211;
      --color-light-yellow: 255, 248, 237;
      --color-light-red: 251, 238, 239;
      --color-blue: 7, 58, 105;
      --color-light-blue: 238, 247, 254;
      --color-turquoise: 15, 192, 199;
      --color-grey-60: 102, 102, 102;

    }
  }