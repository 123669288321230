
.Mui-checked{
    color: #076769 !important;
}

.MuiInputBase-root{
    fieldset{
        border-color: #076769 !important;
        border-width: 0.5px !important;
    }
}

.Mui-disabled{
    fieldset{
        border-color: #999999 !important;
    }   
    input,
    svg {
        color: #999999;
        height: 25px;
    }
}

.MuiSwitch-track{
    background-color: #076769 !important;
}

.MuiSwitch-thumb{
    background-color: #076769 !important;
}