@import 'src/styles/common.module';

.basicDatePicker{
    color: $dark-teal;
    background-color: $white;
    width: 100%;

    fieldset{
        border-color: $dark-teal;
        legend span{
            color: $dark-teal;
        }
    }

    label, svg, input{
        color: $dark-teal;
    }

    input{
        padding: 8.5px 14px;
    }

    &Error{
        fieldset{
            border-color: $red;
        }

    }
}