@import 'src/styles/common.module.scss';

.wrapper {
    overflow: auto;
}

.tableContainer {
    width: var(--table-width, 100%);
    height: var(--table-height, 100%);
    overflow: auto;
    border-radius: 5px;
}

.table {
    --table-background: $white;
    width: 100%;
   
    thead {
        tr {
            background-color: $extra-light-grey;
            box-shadow: inset 0px -1px 0px $light-grey;
            z-index: 1;
            background-color: $white;
            
        }
    }

    td {
        padding: 12px;
    }

    td.selectColumn {
        padding-left: 0;
    }

    th {
        padding-left: 0;
    }
}

.row {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $light-grey;
    border-left: 2px solid transparent;
       
    &:hover {
        background-color: $medium-light-grey;
    }
    &:active {
        background-color: $light-grey;
    }

    &Selected{
        background-color: $extra-light-teal;
        border-left-color: $dark-teal;
        
        &:hover {
            background-color: $extra-light-teal;
        }
    }
}


